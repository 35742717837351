import Typography from 'typography'
import gray from "gray-percentage"
import { MOBILE_MEDIA_QUERY } from "typography-breakpoint-constants"
//to revert to original judahTheme, 1. delete contents imports gray and MOBILE_MEDIA_QUERY, 2. delete code inside Typography() 3. uncomment the following two lines: 
//import judahTheme from 'typography-theme-judah'
//const typography = new Typography(judahTheme)
const typography = new Typography({
  baseFontSize: "18px",
  baseLineHeight: 5 / 3,
  googleFonts: [
    {
      name: "Roboto Condensed",
      styles: ["400", "400i"],
    },
    {
      name: "Oswald",
      styles: ["400", "400i", "700"],
    },
  ],
  headerFontFamily: ["Roboto Condensed", "sans-serif"],
  bodyFontFamily: ["Vollkorn", "Georgia", "serif"],
  headerColor: "hsla(0,0%,0%,1)",
  bodyColor: "hsla(0,0%,0%,0.8)",
  headerWeight: 400,
  bodyWeight: 400,
  boldWeight: 700,
  overrideStyles: ({ adjustFontSizeTo, scale, rhythm }, options) => ({
    a: {
      color: "#e51937",
      textDecoration: "none",
    },
    "a:hover,a:active": {
      color: options.bodyColor,
      textDecoration: "none",
    },
    blockquote: {
      ...scale(1 / 5),
      color: gray(0),
      fontStyle: "italic",
      paddingLeft: rhythm(13 / 16),
      marginLeft: 0,
      borderLeft: `${rhythm(3 / 16)} solid ${gray(80)}`,
    },
    "blockquote > :last-child": {
      marginBottom: 0,
    },
    "blockquote cite": {
      ...adjustFontSizeTo(options.baseFontSize),
      color: options.bodyColor,
      fontWeight: options.bodyWeight,
    },
    "blockquote cite:before": {
      content: '"— "',
    },
    ul: {
      listStyle: "disc",
    },
    [MOBILE_MEDIA_QUERY]: {
      "ul,ol": {
        marginLeft: rhythm(1),
      },
      blockquote: {
        marginLeft: rhythm(-3 / 4),
        marginRight: 0,
        paddingLeft: rhythm(9 / 16),
      },
    },
    "h5,h6": {
      textTransform: "uppercase",
      fontStyle: "italic",
    },
  }),

})

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
